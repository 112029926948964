.row-1 {
    border-left: 1px solid var(--mui-palette-custom-borderColor);
    border-top: 1px solid var(--mui-palette-custom-borderColor);
    /* border-bottom: 1px solid var(--mui-palette-custom-borderColor); */
    padding: 2px;
    padding-left: 0px;
    width: 100%;
    margin: 0;
    height: 1.2rem;
    /* max-width: 8rem; */
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: smaller;
}
.row-last {
    border-left: 1px solid var(--mui-palette-custom-borderColor);
     border-top: 1px solid var(--mui-palette-custom-borderColor);
     border-bottom: 1px solid var(--mui-palette-custom-borderColor); 
    padding: 2px;
    padding-left: 0px;
    width: 100%;
    margin: 0;
    height: 1.2rem;
    /* max-width: 8rem; */
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: smaller;
}
.col {
    width: 100%
}

.row-2 {
    border-right: 1px solid var(--mui-palette-custom-borderColor);
}

.timeslot:hover {
    background-color: var(--mui-palette-custom-borderColor);
    opacity: 0.8;
    color: var(--mui-palette-custom-borderColor);
    cursor: pointer;
}

.timeslot {
    user-select: none;
}

.interval {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.check {
    content: "";
    border: 2px solid var(--mui-palette-custom-borderColor);
    width: .8rem;
    border-radius: 3px;
    height: .8rem;
    display: inline;
    margin-left: 3px;
    background-color: var(--mui-palette-custom-borderColor);
}

.check:hover {
    cursor: pointer;
}

.checked {
    background-color: white;
}

.selected {
    background-color: var(--mui-palette-custom-borderColor);;
    border-color:  var(--mui-palette-custom-backgroundColor);
}

.btn {
    margin-left: 10px;
}

.day:hover {
    opacity: 0.8;
    cursor: pointer;
}

.daySelect {
    background-color: var(--mui-palette-custom-borderColor);;
    color: white;
}

.weekSelect {
    background-color: var(--mui-palette-custom-borderColor);;
    color: white;
    border: .1px solid white;
}

.timerow:hover {
    cursor: pointer;
    opacity: 0.8;
}

.selecting {
    cursor: grabbing;
}
