.rdrCalendarWrapper {
    color: #000000;

    background-color: var(--mui-palette-custom-backgroundColor);
    font-size: 12px;
    border-radius: 4px;
    border-color: var(--mui-palette-custom-borderColor);
    border: 1px solid var(--mui-palette-custom-borderColor);
}

.rdrDateDisplayWrapper {
    background-color: var(--mui-palette-custom-backgroundColor);
}

.rdrDateDisplay {
    margin: 0.833em;
}

.rdrDateDisplayItem {
    border-radius: 4px;
    background-color: var(--mui-palette-custom-buttonColor);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;

    input {
        cursor: pointer;
        height: 2.5em;
        line-height: 2.5em;
        border: 0px;
        background: transparent;
        width: 100%;
        color: var(--mui-palette-custom-buttonTextColor);
    }
}

.rdrDateDisplayItemActive {
    border-color: var(--mui-palette-custom-borderColor);
}

.rdrDateDisplayItemActive {
    input {
        color: var(--mui-palette-custom-text);
    }
}

.rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
}

.rdrMonthAndYearPickers {
    font-weight: 600;

    select {
        appearance: none;
        -webkit-appearance: none;
        border: 0;
        background: transparent;
        padding: 10px 30px 10px 10px;
        border-radius: 4px;
        outline: 0;
        color: var(--mui-palette-custom-buttonTextColor);
        background: var(--mui-palette-custom-buttonColor);
        background-position: right 8px center;
        cursor: pointer;
        text-align: center;

        /* &:hover {
            background-color: rgba(0, 0, 0, 0.07);
        } */
    }
}

.rdrMonthPicker,
.rdrYearPicker {
    margin: 0 5px
}

.rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: var(--mui-palette-custom-buttonColor);
    color: var(--mui-palette-custom-buttonTextColor);
    border: 1px solid var(--mui-palette-custom-borderColor);
    &:hover {
        background-color: #E1E7F0;
    }

    i {
        display: block;
        width: 0;
        height: 0;
        padding: 0;
        text-align: center;
        border-style: solid;
        margin: auto;
        transform: translate(-3px, 0px);
    }
}

.rdrPprevButton {
    i {
        border-width: 4px 6px 4px 4px;
        border-color: transparent var(--mui-palette-custom-buttonTextColor) transparent transparent;
        transform: translate(-3px, 0px);
    }
}

.rdrNextButton {
    i {
        margin: 0 0 0 7px;
        border-width: 4px 4px 4px 6px;
        border-color: transparent transparent transparent var(--mui-palette-custom-buttonTextColor);
        transform: translate(3px, 0px);
    }
}


.rdrWeekDays {
    padding: 0 0.833em;

}

.rdrMonth {
    padding: 0 0.833em 1.666em 0.833em;

    .rdrWeekDays {
        padding: 0;
    }
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
    display: none;
}

.rdrWeekDay {

    font-weight: 400;
    line-height: 2.667em;
    color: rgb(132, 144, 149);
}

.rdrDay {
    background: transparent;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #1d2429;

    &:focus {
        outline: 0;
    }
}

.rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdrDayNumber span {
    color: var(--mui-palette-custom-text);
}

.rdrDayToday .rdrDayNumber span {
    font-weight: 500;

    &:after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 18px;
        height: 2px;
        border-radius: 2px;
        background: var(--mui-palette-custom-text);

    }
}

.rdrDayToday:not(.rdrDayPassive) {

    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected {
        &~.rdrDayNumber span:after {
            background: #fff;

        }
    }
}

.rdrDay:not(.rdrDayPassive) {

    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected {
        &~.rdrDayNumber {
            span {
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;

}

.rdrSelected {
    left: 2px;
    right: 2px;
}

.rdrInRange {
    background-color: var(--mui-palette-custom-buttonColor);
}

.rdrStartEdge {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
    background-color: var(--mui-palette-custom-borderColor);
}

.rdrEndEdge {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
    background-color: var(--mui-palette-custom-borderColor);
}

.rdrSelected {
    border-radius: 1.042em;
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {

    .rdrInRange,
    .rdrEndEdge {
        border-top-left-radius: 1.042em;
        border-bottom-left-radius: 1.042em;
        left: 2px;

    }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {

    .rdrInRange,
    .rdrStartEdge {
        border-top-right-radius: 1.042em;
        border-bottom-right-radius: 1.042em;
        right: 2px;
    }
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {

    .rdrDayInPreview,
    .rdrDayEndPreview {
        border-top-left-radius: 1.333em;
        border-bottom-left-radius: 1.333em;
        border-left-width: 1px;
        left: 0px;
    }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {

    .rdrDayInPreview,
    .rdrDayStartPreview {
        border-top-right-radius: 1.333em;
        border-bottom-right-radius: 1.333em;
        border-right-width: 1px;
        right: 0px;
    }
}


.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid currentColor;
    z-index: 1;
}


.rdrDayStartPreview {
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
}

.rdrDayInPreview {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.rdrDayEndPreview {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
}

.rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 226px;
    border-right: solid 1px var(--mui-palette-custom-borderColor);
    border-bottom: solid 1px var(--mui-palette-custom-borderColor);
    border-left: solid 1px var(--mui-palette-custom-borderColor);
    background-color: var(--mui-palette-custom-backgroundColor);
    color: var(--mui-palette-custom-buttonTextColor);

    .rdrStaticRangeSelected {
        background-color: var(--mui-palette-custom-buttonColor);
        color: var(--mui-palette-custom-buttonTextColor);
        font-weight: 600;
    }
}

.rdrStaticRange {
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    padding: 0;
    background: var(--mui-palette-custom-backgroundColor);
    color: var(--mui-palette-custom-buttonTextColor);

    &:hover,
    &:focus {
        .rdrStaticRangeLabel {
            color: var(--mui-palette-custom-buttonTextColor);
            background-color: var(--mui-palette-custom-buttonColor);
        }
    }
}

.rdrStaticRangeLabel {
    color: var(--mui-palette-custom-text);
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
}

.rdrInputRanges {
    padding: 10px 0;
}

.rdrInputRange {
    align-items: center;
    padding: 5px 20px;
}

.rdrInputRangeInput {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122);

    &:focus,
    &:hover {
        border-color: rgb(180, 191, 196);
        outline: 0;
        color: #333;
    }
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
    content: '';
    border: 1px solid currentColor;
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background: #000000;
    background-color: #000000;
}

.rdrDayPassive {
    pointer-events: none;

    .rdrDayNumber span {
        color: #d5dce0;
    }

    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        display: none;
    }
}

.rdrDayDisabled {
    background-color:var(--mui-palette-custom-backgroundColor);

    .rdrDayNumber span {
        color: #aeb9bf;
    }

    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        filter: grayscale(100%) opacity(60%);
    }
}

.rdrMonthName {
    text-align: left;
    font-weight: 600;
    color: #849095;
    padding: 0.833em;
}

.rdrTimePicker {
    cursor: pointer;
}

.rdrTimePickerIcon {
    cursor: pointer;
    flex: 1;
    font-size: 13.5px;
    margin-right: 0.5em;
    color: black;
}

.rdrTimePickerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid;
    border-color: lightgray !important;
}

.rdrTimePickerContainerActive {
    border-color: initial;
}

.rc-time-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
}

.rc-time-picker * {
    box-sizing: border-box;
}

.rc-time-picker-clear {
    position: absolute;
    right: 25px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 3px;
    margin: 0;
}

.rc-time-picker-clear-icon:after {
    content: "x";
    font-size: 12px;
    font-style: normal;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    height: 20px;
    width: 20px;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
}

.rc-time-picker-clear-icon:hover:after {
    color: #666;
}

.rc-time-picker-input {
    width: 5em;
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    height: 22px;
    cursor: text;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rc-time-picker-input[disabled] {
    color: #ccc;
    background: #f7f7f7;
    cursor: not-allowed;
}

.rc-time-picker-panel {
    z-index: 1070;
    width: 170px;
    position: absolute;
    box-sizing: border-box;
}

.rc-time-picker-panel * {
    box-sizing: border-box;
}

.rc-time-picker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5;
}

.rc-time-picker-panel-narrow {
    max-width: 113px;
}

.rc-time-picker-panel-input {
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: auto;
    line-height: 1.5;
    outline: 0;
    border: 1px solid transparent;
}

.rc-time-picker-panel-input-wrap {
    box-sizing: border-box;
    position: relative;
    padding: 6px;
    border-bottom: 1px solid #e9e9e9;
}

.rc-time-picker-panel-input-invalid {
    border-color: red;
}

.rc-time-picker-panel-select {
    float: left;
    font-size: 12px;
    border: 1px solid #e9e9e9;
    border-width: 0 1px;
    margin-left: -1px;
    box-sizing: border-box;
    width: 56px;
    max-height: 144px;
    overflow-y: auto;
    position: relative;
}

.rc-time-picker-panel-select-active {
    overflow-y: auto;
}

.rc-time-picker-panel-select:first-child {
    border-left: 0;
    margin-left: 0;
}

.rc-time-picker-panel-select:last-child {
    border-right: 0;
}

.rc-time-picker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
}

.rc-time-picker-panel-select li {
    list-style: none;
    margin: 0;
    padding: 0 0 0 16px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rc-time-picker-panel-select li:hover {
    background: #edfaff;
}

li.rc-time-picker-panel-select-option-selected {
    background: #f7f7f7;
    font-weight: bold;
}

li.rc-time-picker-panel-select-option-disabled {
    color: #ccc;
}

li.rc-time-picker-panel-select-option-disabled:hover {
    background: transparent;
    cursor: not-allowed;
}