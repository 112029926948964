h3, h2, h4 {
color: white;
}

.graphicContainer {
    color: white;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 5px;
    grid-auto-rows: 5rem;
    font-size: 16px;
    font-weight: bold;
}

.gridRows {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 20px;
}

.gridCols {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    width: 100%;
    text-align: center;
    height: 100%;
}

.row-1{
    color: white;
    grid-column: 1/9;
}

.row2{
    grid-column: 1/8;
    background-color: #2C90CA;
}

.row3{
    grid-column: 1/7;
    background-color: #3E83B8;
}

.row4{
    grid-column: 1/6;
    background-color: #236A9A;
}

.row5{
    grid-column: 1/5;
    background-color: #084B7A;
}

.row6{
    grid-column: 1/4;
    background-color: #002D65;
}

.col2{
    grid-column: 8/9;
    grid-row: 2/7;
    background-color: #BCBDC0;
}

.col3{
    color: white;
    grid-column: 7/8;
    grid-row: 3/7;
    background-color: #FF7A2C;
}

.col4{
    color: white;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.25fr 1fr;
    grid-gap: 5px;
    grid-column: 6/7;
    grid-row: 4/7;
}

.col41 {
    background-color: #FFB41D;
}

.col42 {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.col421 {
    background-color: #FFB41D;
}

.col422 {
    background-color: #FFB41D;
}

.col5{
    grid-column: 5/6;
    grid-row: 5/7;
    background-color: #F8D974;
}

.col6{
    background-color: #98B2B2;
}

@media screen and (max-width: 1050px) {
    #SBO {
        display: none;
    }

    #SBE {
        display: none;
    }
  }

  @media screen and (max-width: 720px) {
    .gridCols {
        display: none;
    }
  }

  @media screen and (max-width: 400px) {
    .gridRows {
        display: none;
    }
  }