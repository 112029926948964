.imgUpload{
    background-blend-mode: multiply;
    vertical-align: middle;
    text-align: center;
    color: transparent;
    transition: all .3s ease;
    text-decoration: none;
    margin-bottom: 20px;
    background-image: url("../../../public/image_placeholder.svg");
    background-size:cover;
    background-position: center;
    height: 150px; 
    width: 150px;
    border-radius: 12%;
}
.imgUpload-hover:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    transition: all .3s ease;
    text-decoration: none;
}
.imgUpload span {
    display: inline-block;
    padding-top: 2.5em;
    display: none;
}
.imgUpload-hover:hover span {
    display: block;
}