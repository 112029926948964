#root > div > div > nav > div.MuiDrawer-root.MuiDrawer-docked > div {
    background: var(--mui-palette-custom-navigationBackgroundColor);
}

.borderGradient {
    padding: 0.5rem 1rem;
    position: relative;
    background-image:  var(--mui-palette-custom-headerColor);
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 15px;
}

.borderGradient::before {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    z-index: -1;
    background-color: var(--mui-palette-custom-backgroundColor);
    border-radius: 20px;
}

.progress {
    width: 100%;
    background-color: rgb(58, 58, 58);
    height: 1.5rem;
}

.progress-bar {
    background-color: var(--primary-color);
}

#leftBar {
    border-left: 1px solid var(--primary-color);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.rightBar {
    border-right: 1px solid var(--primary-color);
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
}

.thumbnail:hover {
    background-color: var(--mui-palette-custom-borderColor);
    cursor: pointer;
}

.thumbnail:hover .thumbtext {
    color: var(--mui-palette-custom-backgroundColor)
}

.statusBox:hover {
    cursor: pointer;
}

.statusBox:hover .statusText {
    color: #7c8798;
}

*::-webkit-scrollbar {
    width: 0.4rem;
  }
*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
  }
*::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-color: #aaa;
    border-radius: 10px;
  }